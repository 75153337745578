<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="Etat journalier" name="first" v-if="etat == 1">
        <EtatJournalierComponent />
    </el-tab-pane>
    <el-tab-pane label="Reunion" name="second" v-if="metting == 1">
        <!-- <GbAgencePresence v-bind:agencesOption="agencesOption" v-bind:agenceSelect="agenceSelect" /> -->
    </el-tab-pane>
    <el-tab-pane label="Inventaire" name="third" v-if="inventaire == 1">
        <!-- <GbutilisateurPresence/> -->
    </el-tab-pane>
    <el-tab-pane label="Incident" name="four" v-if="incident == 1">
        <!-- <GbutilisateurPresence/> -->
    </el-tab-pane>
  </el-tabs>
</template>
<script>

    // Utilities
    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    import axios from 'axios';

    import EtatJournalierComponent from './EtatJournalierComponent.vue';
    // import GbAgencePresence from './GbAgencePresenceComponent.vue';
    // import GbutilisateurPresence from './GbutilisateurPresenceComponent.vue';

    export default {

        components: {
            EtatJournalierComponent,
            // GbAgencePresence,
            // GbutilisateurPresence
        },

        data() {
        return {
            activeName: 'first',
            agencesOption: [],
            agenceSelect: [],
            usersOption: [],
            userSelect: [],
            etat : 0,
            inventaire : 0,
            metting : 0,
            incident : 0,
        };
        },
        methods: {

            handleClick(tab, event) {
                NProgress.start();
                console.log(tab, event);
                NProgress.done();
            },
            
            async loadAcces() {

                  NProgress.start();

                // try {
                  
                    const response = await axios.get('xperf/access',);
                    var menu = response.data.data.menu;

                    if (menu.includes('report')) {


                        response.data.data.section['report'].forEach(element => {
                            
                            if(element == 'etat' ){this.etat = 1;}
                            else if(element == 'inventaire' ){this.inventaire = 1;}
                            else if(element == "metting" ){this.metting = 1;}
                            else if(element == "incident" ){this.incident = 1;}
                        });

                    }

                  NProgress.done();

                  

            },

            async loadFilter()
            {
                NProgress.start();

                try {
                  
                    const response = await axios.get('xperf/report/filter',);
                    var res_agences = response.data.agences;
                    var res_users = response.data.users;

                    var agences = [];
                    var users = [];

                    var array_agence = [];
                    var array_users = [];



                    res_agences.forEach(element => {
                    
                        if (!agences.includes(element.code)) {
                            agences.push(element.code);
                            array_agence.push({
                                label: element.nom,
                                value: element.code
                            });
                        }
                    });

                    res_users.forEach(element => {
                    
                        if (!users.includes(element.id)) {
                            users.push(element.id);
                            array_users.push({
                                label: element.name,
                                value: element.id
                            });
                        }
                    });

                    if(array_agence.length >1){
                       array_agence.unshift({
                                label: 'Tous',
                                value: 'Tous'
                        }); 
                    }
                    this.agencesOption = array_agence;
                    this.agenceSelect = this.agencesOption[0];

                    this.usersOption = array_users;
                    this.userSelect = this.usersOption[0];


                } catch (error) {
                    this.$notify({
                    type: 'error',
                    title: "Echec de recuperation"
                });
                }

                NProgress.done();

            },

        },

        created () {
            this.loadFilter();
            this.loadAcces();
        }
    };
</script>